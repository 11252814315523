import axios from 'axios';

// ~~~~~~~~~~~~~~~~~~~ support function ~~~~~~~~~~~~~~~~~~~~~~~~~~
function getDashedDate(dateStr){
  const myDate = dateStr.split("-");
  var newDate = new Date( myDate[0], myDate[1] - 1, myDate[2]);
  //console.log(newDate.getTime());
  return newDate.getTime()/1000;
}

// ~~~~~~~~~~~~~~~~~~~ support function ~~~~~~~~~~~~~~~~~~~~~~~~~~
function toTimestamp(strDate){
   var datum = Date.parse(strDate);
   return datum/1000;
}

// ~~~~~~~~~~~~~~~~~~~ support function ~~~~~~~~~~~~~~~~~~~~~~~~~~
function toDashedDate(strDate){
  var datum = Date.parse(strDate);
	const value = new Date(datum);
  return [value.getFullYear() + "-" + value.getMonth() + "-" + value.getDate()];
}


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// check overlap
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
function checkOverlap(CheckInData, CheckOutData, DBCheckInDate, DBCheckOutDate){
	const pmsChInNum = toTimestamp(CheckInData);
	const pmsChOutNum = toTimestamp(CheckOutData);
	const dbChInNum = getDashedDate(DBCheckInDate);
	const dbChOutNum = getDashedDate(DBCheckOutDate);
	console.log("pmsChInNum:  ", pmsChInNum);
	console.log("dbChInNum:   ", dbChInNum); 
  console.log("pmsChOutNum: ", pmsChOutNum);   
  console.log("dbChOutNum:  ", dbChOutNum);
	if (
			(pmsChInNum < dbChOutNum & pmsChInNum >= dbChInNum) |
			(pmsChOutNum <= dbChOutNum & pmsChOutNum > dbChInNum) |
			(pmsChInNum <= dbChInNum & pmsChOutNum >= dbChOutNum) |
			(dbChInNum <= pmsChInNum & dbChOutNum >= pmsChOutNum)
			) {
				console.log("Overlap");
				return true;
			} else {
				console.log("No overlap");
				return false;
			}
}



//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// get reservation data
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
async function getReservation(baseAddr, hotelNumber, reserveNumber) {
  let rerserveData = {};
  console.log(baseAddr);
  await axios
    .get(baseAddr + "/scrape/"+ hotelNumber +"/reserve/?number=" + reserveNumber)
    .then((response) => {
      rerserveData = response.data;                  
    });
  return rerserveData;
}


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// set booking data
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function setBookingData(data,userId,hotelId) {
  const bookingData = {
    "user_id": userId, 
    "property_id": hotelId, 
    "total_adults": data.numAdults, 
    "total_children": data.numKids,
    "total_babies": 0, 
    "planed_check_in_time": null, 
    "planed_check_out_time": null, 
    "special_request": null,
    "room_type": data.roomType, 
    "room_plan": data.boardType, 
    "phone_number": data.customer.phone, 
    "phone_number_cc": null, 
    "check_in_date": toDashedDate(data.checkInDate),
    "check_out_date": toDashedDate(data.checkOutDate),
    "estimated_arrival_time": null, 
    "status_id": 18, // pre check in
    "reservation_num": data.id
  }
  return bookingData;
}



export {checkOverlap, getReservation, setBookingData}

