<template>
  <v-app>
  <!-- Must have the app property -->
    <v-navigation-drawer app
      permanent
    >
      <div
        class="d-flex flex-column"
      >
        <v-btn        
          class="mt-6 mb-6 mx-auto"
          color="yellow lighten-4"
          depressed
          elevation="3"
          :loading="loadingStatus"
          :disabled="loadingStatus"
          @click="getList"
        >
          Scrape
        </v-btn>
        <v-card
          elevation="1"
          class="mt-3 mb-6 mx-auto pl-6 pr-6 pt-2 pb-2"
          color="yellow lighten-5"
        >
          Reservation number
        </v-card>
        

        <v-btn
          class="mt-2 mx-auto"
          v-for="(i, n) in reservList"
          :key="n"       
          color="yellow lighten-4"
          depressed
          elevation="3"
          @click="getReservation(n)"   
        >
          {{i}}
        </v-btn>
        
        <v-text-field 
          prepend-icon="mdi-home"
          class="mt-10 ml-5 mr-5 mx-auto"
          label="Reservation number"
          :rules="reservationRules"
          v-model="reservationNumber"
        >
        </v-text-field>
      
        <v-btn
          
          class="mt-6 mb-6 mx-auto"
          color="yellow lighten-4"
          depressed
          elevation="3"
          :loading="bookingState"
          :disabled="bookingState"
          @click="addBooking"
        >
          Add to booking
        </v-btn>
      
      </div>

    <!-- -->
    </v-navigation-drawer>

  <v-app-bar app
    absolute
    color = "yellow lighten-4"
  >
    <v-toolbar-title>Hotel PMS Scraper </v-toolbar-title>
    <span v-if="showTitle">
      &nbsp;- &nbsp;scraping&nbsp;: {{currentReservation}}, trial: {{trial}}
    </span>
    <span v-if="showBookingTitle">
      &nbsp;- &nbsp;add bookinbg&nbsp;: {{reservationNumber}}, status: {{bookingStatus}}
    </span>
    
  </v-app-bar>

  <v-main>
    <v-card
      elevation="2"
      class="mt-6 mb-6 ml-6 mr-6 mx-auto"
      v-for="(item, res) in reserveArray"
      :key="res"        
    >
      <v-card-title>
        Reservation data - {{item.id}}
      </v-card-title>
      <v-card-subtitle>
        Last updated: {{item.lastUpdated}}        
      </v-card-subtitle>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">First name: {{item.customer.firstName}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Last name: {{item.customer.lastName}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Email: {{item.customer.email}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Phone number: {{item.customer.phone}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Rooms: {{item.rooms}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Nights: {{item.nights}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Adults/Kids: {{item.numAdults}}/{{item.numKids}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Price: {{item.totalPrice}} {{item.currency}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Room type: {{getRoomType(item)}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Board type: {{getBoardType(item)}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Check in: {{item.checkInDate}}</v-chip>
      <v-chip class="mt-3 mb-6 ml-6 mr-6 mx-auto" :color="changeColor(item.customer.email)">Check out: {{item.checkOutDate}}</v-chip>
    </v-card>


    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="red accent-2"
      rounded="pill"
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red lighten-5"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    
  </v-main>
</v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import axios from 'axios';
//import {getReserveArray} from './components/scrapeAd';
import {checkOverlap, getReservation, setBookingData} from '../src/components/utilities';

export default {
  
  name: 'App',

  components: {
    //HelloWorld,
  },

  data: () => ({
    reservationRules: [
      reservationNumber => !!reservationNumber || 'Required.',
      reservationNumber => (reservationNumber && reservationNumber.length >= 3) || 'Min 3 characters',
    ],
    bookingProcessing: false,
    getResponse: null,
    reservationNumber: null,
    bookingStatus: null,
    snackbar: false,
    snackbarTimeout: 3000,
    hotelNumer: 967,
    errorText: "",
    currentReservation: null,
    trial: 0,
    loading: false,
    reservList: ['IZW', 'def', 'ghj', 'khj'],
    reserveArray:[
      {
        "id": "IZW",
        "rooms": "1",
        "nights": "4",
        "numAdults": "2",
        "numKids": "0",
        "lastUpdated": "08.11.2022 15:30:29",
        "totalPrice": "800.00",
        "currency": "GEL",
        "customer": {
          "firstName": "tina",
          "lastName": "onyx",
          "email": "onyx@tbilisi.com",
          "phone": "6446437347"
        },
        "roomInfo": [
          {
            "roomType": "Twin garden view",
            "boardType": "Room Only",
            "roomNumber": "19",
            "adults": "2",
            "kids": "0"
          }
        ],
        "checkInDate":"Nov 26, 2022",
        "checkOutDate":"Nov 27, 2022"
      },
      {
        "id": "IZU",
        "rooms": "1",
        "nights": "1",
        "numAdults": "1",
        "numKids": "1",
        "lastUpdated": "06.11.2022 20:54:07",
        "totalPrice": "333.00",
        "currency": "GEL",
        "customer": {
          "firstName": "Lasha",
          "lastName": "Niazashvili",
          "email": null,
          "phone": ""
        },
        "roomInfo": [
          {
            "roomType": "triple",
            "boardType": "Half Board",
            "roomNumber": "13",
            "adults": "1",
            "kids": "1"
          }
        ],
        "checkInDate":"Oct 20, 2022",
        "checkOutDate":"Oct 21, 2022"
      },
      {
        "id": "IZT",
        "rooms": "1",
        "nights": "1",
        "numAdults": "1",
        "numKids": "0",
        "lastUpdated": "05.11.2022 12:42:19",
        "totalPrice": "150.00",
        "currency": "GEL",
        "customer": {
          "firstName": "gela",
          "lastName": "jorjoladze",
          "email": "",
          "phone": ""
        },
        "roomInfo": [
          {
            "roomType": "Single",
            "boardType": "Half Board",
            "roomNumber": "105",
            "adults": "1",
            "kids": "0"
          }
        ],
        "checkInDate":"Sep 6, 2022",
        "checkOutDate":"Sep 9, 2022"
      }

    ],
    emptyObj: {
      "id": null,
      "rooms": null,
      "nights": null,
      "numAdults": null,
      "numKids": null,
      "lastUpdated": null,
      "totalPrice": null,
      "currency": null,
      "customer": {
        "firstName": null,
        "lastName": null,
        "email": null,
        "phone": null,
      },
      "roomInfo": [
        {
          "roomType": null,
          "boardType": null,
          "roomNumber": null,
          "adults": null,
          "kids": null
        }
      ],
      "checkInDate":null,
      "checkOutDate":null
    },
    rerserData: {
      "id": "IZT",
      "rooms": "1",
      "nights": "1",
      "numAdults": "1",
      "numKids": "0",
      "lastUpdated": "05.11.2022 12:42:19",
      "totalPrice": "150.00",
      "currency": "GEL",
      "customer": {
        "firstName": "John",
        "lastName": "Doe",
        "email": "johndoe@jdfhdj.fh",
        "phone": "12321464",
      },
      "roomInfo": [
        {
          "roomType": "Single",
          "boardType": "Half Board",
          "roomNumber": "105",
          "adults": "1",
          "kids": "0"
        }
      ]
    },
    testId: null,
    //baseAddr: "http://localhost:8070",
    apiServerAddr: "https://sapi.hip-e.me",
    baseAddr: "http://207.154.214.164:8070"
    //
  }), 
  methods: {
    

    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // get reservations list
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    async getList(){      
      this.loading = true;
      this.reservList = [];
       this.reserveArray = [];
      await axios
        .get(this.baseAddr+"/scrape/"+ this.hotelNumer +"/list/")
        .then((response) => {
          const data = response.data; // [{}, {}]
          console.log(data);
          if ("error" in data) {
            this.errorText = data.error;
            this.snackbar = true;
          } else {
            this.reservList = data;
            this.loading = false;
          }
          
        });
      this.loading = true;
      console.log("Array length: ", this.reservList.length);
      
      /*if (this.reservList.length > 0) {
        this.reserveArray = await getReserveArray(this.reservList);
      } */
      if (this.reservList.length > 0) {
        for (let reserveNum = 0; reserveNum < this.reservList.length;  reserveNum++) {
          if (this.reservList[reserveNum] != null) {
            if (this.reservList[reserveNum].length > 0) {
              this.currentReservation = this.reservList[reserveNum];
              let result = "";
              this.trial = 0;
              while (Object.keys(result).length == 0  & this.trial < 5) {
                result = await getReservation(this.baseAddr, this.hotelNumer, this.reservList[reserveNum]);
                this.trial++;
                console.log(result);
                // check if there was an error retrieving the data
                if ("error" in result) {
                  this.errorText = result.error;
                  this.snackbar = true;                  
                  result = "";
                }
              }

              if (Object.keys(result).length > 0) {
                this.reserveArray.push(result);
                
                let sqlObj = {
                  "first_name": result.customer.firstName,
                  "last_name": result.customer.lastName,
                  "email": result.customer.email,
                  "registered_at": "WebPortal",
                  "profile_image": null,
                  "device_token": null,
                  "password": null,
                  "phone": result.customer.phone,
                  "phone_cc": null
                };
                console.log(sqlObj);

                //const json = JSON.stringify(sqlObj);
                if (result.customer.email != null) {
                  if (result.customer.email.length > 0) {
                    try {
                      let postRes = "";
                      await axios.post(this.apiServerAddr+"/users/register/", sqlObj, {
                        headers: {                    
                          'Content-Type': 'application/json',
                        }
                      }).then((response) => {
                        postRes = response.data;                          
                      });
                      console.log(postRes);
                    } 
                    catch {
                      console.error("Failed to write to database");
                    }
                    
                  }                  
                }
                
              } else {
                let tempObj = this.emptyObj;
                tempObj.id = this.reservList[reserveNum];
                this.reserveArray.push(JSON.parse(JSON.stringify(tempObj)));
              }
            }            
          }
        }
      }  
      console.log(this.reserveArray)
      this.loading = false;    
        
    },

    
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // add booking
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    async addBooking(){
      this.bookingProcessing = true;
      console.log("Booking ", this.reservationNumber);
      //QTO
      const result = await getReservation(this.baseAddr, this.hotelNumer, this.reservationNumber);
      console.log(result);
      this.bookingStatus = "Searching for existing booking";
      let hipeReservCheckInDate = null;
      let hipeReservCheckOutDate = null;
      //get user      
      //const userEmail = "yuv.carmel@gmail.com";
      const userEmail = result.customer.email;
      let hotelCheckInData = result.checkInDate;
      let hotelCheckOutData = result.checkOutDate;
      let userId = null; 
      
      // find user ID
      await axios
        .get(this.apiServerAddr+"/users/getid?email="+userEmail)
        .then((response) => {
          const data = response.data; // [{}, {}]
          console.log(data);
          this.getResponse = data;          
        });
      console.log("Response: ", this.getResponse);
      // user user ID to search for booking
      if ("error" in this.getResponse) {
        this.errorText = "User not found";
        this.snackbar = true;
        this.bookingStatus = "";
        this.bookingProcessing = false;
        return null;
      } else {
        userId = this.getResponse.id;
        console.log(userId);
        // look for exiting booking
        const request = this.apiServerAddr+"/booking?user_id="+userId+"&property_id="+this.hotelNumer;
        await axios
        .get(request)
        .then((response) => {
          const data = response.data; // [{}, {}]
          console.log(data);
          console.log(typeof data);
          this.getResponse = data;          
        });
        // check the response type
        if (typeof this.getResponse === 'object') {
          if ("error" in this.getResponse) {
            this.errorText = "Booking search failed.";
            this.snackbar = true;
            this.bookingStatus = "";
            this.bookingProcessing = false;
            return null;
          } else { // booking was found
            hipeReservCheckInDate = this.getResponse.check_in_date;
            console.log("Hipe last check-in date: ",hipeReservCheckInDate);
            hipeReservCheckOutDate = this.getResponse.check_in_date;
            console.log("Hipe last check-out date: ",hipeReservCheckOutDate);
            this.bookingStatus = "A booking exists in hipe database.";
          }
        } else {
          this.errorText = "Booking search came empty.";
          this.snackbar = true;
          this.bookingStatus = "";
          this.bookingProcessing = false;
          // later need to use this case!!!
        }
        const overlap = checkOverlap(hotelCheckInData, hotelCheckOutData, hipeReservCheckOutDate, hipeReservCheckOutDate);
        console.log(overlap);
        if (!overlap){
          // add booking
          this.bookingStatus = "Adding booking";
          const bookingData = setBookingData(result,userId,this.hotelNumer);
          console.log("bookingData: ", bookingData);
          try {
            await axios
            .post(this.apiServerAddr + '/booking', bookingData)          
            .then((response) => {
              const data = response.data; // [{}, {}]
              console.log(data);
              this.getResponse = data;          
            });
            console.log("Response: ", this.getResponse);
            this.bookingStatus = "Booking successful";
          } catch {
            this.errorText = "Booking with server failed.";
            this.snackbar = true;
            this.bookingStatus = "";
            this.bookingProcessing = false;
          }
          
        }
      }      
      this.bookingStatus = "";
      this.bookingProcessing = false;
      return true;
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    showValue(id){
      this.testId = id;
    },
    getRoomType(record) {
      return record.roomInfo[0].roomType;      
    },
    getBoardType(record) {
      return record.roomInfo[0].boardType;      
    },
    changeColor(input) {
      if (input == null) {
         return "red lighten-4";
      } else {
        if (input.length == 0) {
          return "brown lighten-4";
        } else {
          return "green lighten-4";
        }        
      }

    }
  },
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // computed
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  computed: {    
    loadingStatus() {
      return this.loading;
    },
    bookingState(){
      return this.bookingProcessing;
    },
    showTitle() {
      if (this.currentReservation == null) {
        return false;
      } else {
        return true;
      }
    },
    showBookingTitle(){
      if (this.reservationNumber == null) {
        return false;
      } else {
        return true;
      }
    }


    
  }

};
</script>
